import { type LoaderFunctionArgs, json, type ActionFunctionArgs } from "@remix-run/node"
import { Public } from "~/utils/remix"

export const action = Public(async ({ request, context }: ActionFunctionArgs) => {
  throw json({ msg: "path not found" }, { status: 404 })
})

export const loader = Public(async ({ request, context }: LoaderFunctionArgs) => {
  throw json({ msg: "path not found" }, { status: 404 })
})

export default function Component() {
  return <div>404</div>
}
